

















import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';

import Form from '@/components/Forms/Form.vue';
import OfficeMap from '@/components/OfficeMap.vue';
import Awards from '@/components/Awards.vue';

@Component({
  components: {
    Form,
    OfficeMap,
    Awards,
  },
})
export default class PropertyAlerts extends Mixins(View) {
}
